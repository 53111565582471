var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "log-cards" },
    [
      _c("div", { staticClass: "log-cards__date" }, [
        _vm._v(" " + _vm._s(_vm.contents.date) + " "),
      ]),
      _vm._l(_vm.contents.items, function (content, j) {
        return _c("LogCard", {
          key: j,
          attrs: { content: content, "log-card-type": _vm.logCardType },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }