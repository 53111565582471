<template>
  <div class="log-cards">
    <div class="log-cards__date">
      {{ contents.date }}
    </div>
    <LogCard
      v-for="(content, j) in contents.items"
      :key="j"
      :content="content"
      :log-card-type="logCardType" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    LogCard: () => import('@/components/mypage/LogCard.vue')
  },

  props: {
    logCardType: {
      type: String,
      default: 'mileHistory'
    },

    contents: {
      type: Object,
      default: null
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.log-cards {
  padding: 1rem 1.6rem 0;
  background-color: variables.$white01;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &__date {
    color: variables.$grey01;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}
</style>
